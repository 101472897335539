import consumer from "./consumer"

consumer.subscriptions.create("NotificationChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (Notification.permission === 'granted' && $('.push_notification_sweetpay').length > 0) {
      var title = 'SweetPay Notification'
      var body = data.message
      var count = data.count
      var options = { body: body }
      $('#notification-items').html('<span class="count"></span>')
      // $('.count').text(count)
      // $('.count').attr('id',"count-"+count)
      new Notification(title, options)
    }

    if($('.push_notification_sweetpay').length > 0){
      notify()
    }
  }
});
