document.addEventListener("turbolinks:load", () => {
  $('#terms_and_codition_box').scroll(function(){
    if($('#terms_and_codition_box').prop('scrollTop') + $('#terms_and_codition_box').prop('offsetHeight') >= $('#terms_and_codition_box').prop('scrollHeight')){
      $('#agreement_document_read').val('marked_read')
      validationCheckerConsumerAgreement()
    }
  });

  $('#agreement_form input').keyup(function(){
    validationCheckerConsumerAgreement()
  })

  $('#agreement_check_box').change(function(){
    if($('#agreement_check_box').is(':checked')){
      $('#consumer_agreement_btn').prop('disabled', false)
    }
    else{
      $('#consumer_agreement_btn').prop('disabled', true)
    }
    validationCheckerConsumerAgreement()
  });

  if($('#agreement_form').length == 1){
    $('#agreement_form').validate({
      rules: {
        'routing_number': {
          minlength: 9,
          maxlength: 9,
          required: true,
          digits: true,
          allZero: true
        },
        'account_number': {
          required: true,
          digits: true,
          minlength: 6,
          maxlength: 25,
          allZero: true
        },
        'bank_name':{
          maxlength: 256,
          required: true
        }
      },
      messages: {
        'routing_number': {
          minlength: 'Sorry, routing number must be 9 digits',
          maxlength: 'Sorry, routing number must be 9 digits',
          digits: 'Sorry, only numbers are allowed.',
          allZero: 'Sorry, number is not valid.'

        },
        'account_number': {
          maxlength: "Sorry, account number must be between 6 and 25 digits.",
          minlength: "Sorry, account number must be between 6 and 25 digits.",
          digits: 'Sorry, only numbers are allowed.',
          allZero: 'Sorry, number is not valid.'
        },
        'bank_name':{
          maxlength: "Sorry, bank name must be less then 256 words"
        }
      }
    });
  }


  $.validator.addMethod("allZero", function(value, element) {
    return this.optional(element) || !(! + value);
  });
});


window.validationCheckerConsumerAgreement = function(){
  let inputsWithValues = 0, totalInputs = 0;
  let myInputs         = $('input:required');

  myInputs.each(function(e) {
    if ($(this).val()) {
      if($(this).valid()){
        inputsWithValues += 1;
        $(this).prev().prev().css('color', '#3c7643')
      }
      else{
        $(this).prev().prev().css('color', '#a94442')
      }
    }
  });

  totalInputs = myInputs.length
  if($('#agreement_check_box').length == 1){
    totalInputs += 1
    inputsWithValues += $('#agreement_check_box:checked').length == 1 ? 1 : 0
  }

  totalInputs += 1
  if($('#agreement_document_read').val().length > 0){
    inputsWithValues += 1
  }

  if (inputsWithValues == totalInputs) {
    $("#consumer_agreement_btn").prop("disabled", false);
  } else {
    $("#consumer_agreement_btn").prop("disabled", true);
  }
}

