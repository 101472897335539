let applicantForms = ['basic_detail_v3', 'contact_detail_v3', 'loan_detail_v3', 'identity_detail_v3', 'resident_detail_v3', 'job_detail_v3', 'bank_detail_v3', 'co_basic_detail_v3', 'co_loan_detail_v3', 'co_identity_detail_v3', 'co_resident_detail_v3', 'co_job_detail', 'document_upload_form', 'product_detail_form', 'igw_ssn_detail_v3']


document.addEventListener('turbolinks:load', () => {
  if($('#basic_detail_v3').length == 1){
    $('#basic_detail_v3').validate({
      rules: {
        'applicant[first_name]': {
          required: true,
          lastNameValidation: true,
          minlength: 2,
          maxlength: 40
        },
        'applicant[last_name]': {
          required: true,
          lastNameValidation: true,
          minlength: 2,
          maxlength: 40
        },
        'applicant[address]': {
          required: true,
          addressregx: true,
          poboxregex: true,
          minlength: 2,
          maxlength: 100
        },
        'applicant[city]': {
          required: true,
          minlength: 2,
          cityregex: true,
          maxlength: 40
        },
        'applicant[apt_unit]': {
          maxlength: 20,
          aptunit: true
        },
        'applicant[state_id]': {
          required: true
        },
        'applicant[zip]': {
          required: true,
          minlength: 5,
          maxlength: 5,
          zipregex: true
        },
        'applicant[email]': {
          required: true,
          emailregex: true,
          minlength: 2,
          maxlength: 100
        },
        'applicant[phone]': {
          required: true,
          minlength: 12,
          maxlength: 12,
          phoneregex: true,
        }
      },
      messages: {
        'applicant[first_name]': {
          lastNameValidation: 'Please use alphabets only.',
          required: 'Please enter first name.',
          minlength: 'Sorry, your first name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your first name must be between 2 and 40 characters long.'
        },
        'applicant[last_name]': {
          lastNameValidation: 'Please use alphabets only.',
          required: 'Please enter last name.',
          minlength: 'Sorry, your last name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your last name must be between 2 and 40 characters long.'
        },
        'applicant[address]': {
          required: 'Please enter your street address.',
          poboxregex: 'Address must be a physical address',
          minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.'
        },
        'applicant[city]': {
          required: 'Please enter city name.',
          minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          cityregex: 'Please enter a valid city name.'
        },
        'applicant[apt_unit]': {
          maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
          aptunit: 'Sorry, only letters(a-z), numbers, period(.), hyphen (-) & hash (#) are allowed.'
        },
        'applicant[state_id]': {
          required: 'Please select State.'
        },
        'applicant[zip]': {
          required: 'Please enter zip code.',
          zipregex: 'Please enter valid zip code.',
          minlength: 'Zip code must be 5 numeric digits only.',
          maxlength: 'Zip code must be 5 numeric digits only.'
        },
        'applicant[email]': {
          required: 'Please enter email address.',
          emailregex: 'Please enter valid email address.',
          maxlength: 'Please enter valid email address.',
          minlength: 'Please enter valid email address.'
        },
        'applicant[phone]': {
          required: 'Please enter mobile phone.',
          minlength: 'Your phone number must be 10 numeric digits only.',
          maxlength: 'Your phone number must be 10 numeric digits only.',
          phoneregex: 'Please enter valid mobile phone.',
        }
      }
    });
  }

  if($('#prequel_detail_v3').length == 1){
    var min_loan_amount = parseInt($('#min_loan_amount').val())
    var max_loan_amount = parseInt($('#max_loan_amount').val())
    $('#prequel_detail_v3').validate({
      rules: {
        'applicant[first_name]': {
          required: true,
          lastNameValidation: true,
          minlength: 2,
          maxlength: 40
        },
        'applicant[last_name]': {
          required: true,
          lastNameValidation: true,
          minlength: 2,
          maxlength: 40
        },
        'applicant[loan_amount]': {
          required: true,
          number: true,
          min: min_loan_amount,
          max: max_loan_amount
        },
        'applicant[address]': {
          required: true,
          addressregx: true,
          poboxregex: true,
          minlength: 2,
          maxlength: 100
        },
        'applicant[city]': {
          required: true,
          minlength: 2,
          cityregex: true,
          maxlength: 40
        },
        'applicant[apt_unit]': {
          maxlength: 20,
          aptunit: true
        },
        'applicant[state_id]': {
          required: true
        },
        'applicant[zip]': {
          required: true,
          minlength: 5,
          maxlength: 5,
          zipregex: true
        },
          'applicant[email]': {
            required: true,
            emailregex: true,
            minlength: 2,
            maxlength: 100
          },
          'applicant[phone]': {
            required: true,
            minlength: 12,
            maxlength: 12,
            phoneregex: true,
          }
      },
      messages: {
        'applicant[first_name]': {
          lastNameValidation: 'Please use alphabets only.',
          required: 'Please enter first name.',
          minlength: 'Sorry, your first name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your first name must be between 2 and 40 characters long.'
        },
        'applicant[last_name]': {
          lastNameValidation: 'Please use alphabets only.',
          required: 'Please enter last name.',
          minlength: 'Sorry, your last name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your last name must be between 2 and 40 characters long.'
        },
        'applicant[loan_amount]': {
          required: 'Please enter requested amount.',
          number: 'Please enter requested amount.',
          min: 'Sorry, requested amount must be greater than ' + min_loan_amount + '.',
          max: 'Sorry, requested amount must be between ' + min_loan_amount + ' and ' + max_loan_amount + '.'
        },
        'applicant[address]': {
          required: 'Please enter your street address.',
          poboxregex: 'Address must be a physical address',
          minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.'
        },
        'applicant[city]': {
          required: 'Please enter city name.',
          minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          cityregex: 'Please enter a valid city name.'
        },
        'applicant[apt_unit]': {
          maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
          aptunit: 'Sorry, only letters(a-z), numbers, period(.), hyphen (-) & hash (#) are allowed.'
        },
        'applicant[state_id]': {
          required: 'Please select State.'
        },
        'applicant[zip]': {
          required: 'Please enter zip code.',
          zipregex: 'Please enter valid zip code.',
          minlength: 'Zip code must be 5 numeric digits only.',
          maxlength: 'Zip code must be 5 numeric digits only.'
        },
        'applicant[email]': {
          required: 'Please enter email address.',
          emailregex: 'Please enter valid email address.',
          maxlength: 'Please enter valid email address.',
          minlength: 'Please enter valid email address.'
        },
        'applicant[phone]': {
          required: 'Please enter mobile phone.',
          minlength: 'Your phone number must be 10 numeric digits only.',
          maxlength: 'Your phone number must be 10 numeric digits only.',
          phoneregex: 'Please enter valid mobile phone.',
        }
      }
    });
  }

  if($('#reset_password_form_admin').length == 1){
    $("#reset_password_form_admin").validate({
      rules: {
        "admin_user[password]": {
          required: true,
          passwordregex: true
        },
        "admin_user[password_confirmation]": {
          required: true,
          passwordregex: true
        }
      },
      messages: {
        "admin_user[password]": {
          required: "The password is required and can\'t be empty",
          passwordregex: 'The password must have 8 or more characters with a mix of letters, numbers & symbols.'
        },
        "admin_user[password_confirmation]": {
          required: "The confirm password is required and can\'t be empty",
          passwordregex: 'The password must have 8 or more characters with a mix of letters, numbers & symbols.'
        }
      },
    })
    $.validator.addMethod("passwordregex", function(value, element) {
      return this.optional(element) || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/i.test(value);
    });
  }
  if($('#change_password_form_member').length == 1){
    $("#change_password_form_member").validate({
      rules: {
        "member[password]": {
          required: true,
          passwordregex: true
        },
        "member[password_confirmation]": {
          required: true,
          passwordregex: true
        }
      },
      messages: {
        "member[password]": {
          required: "The password is required and can\'t be empty",
          passwordregex: 'The password must have 8 or more characters with a mix of letters, numbers & symbols.'
        },
        "member[password_confirmation]": {
          required: "The confirm password is required and can\'t be empty",
          passwordregex: 'The password must have 8 or more characters with a mix of letters, numbers & symbols.'
        }
      },
    })
    $.validator.addMethod("passwordregex", function(value, element) {
      return this.optional(element) || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/i.test(value);
    });
  }
  $('#apply-forms').on('click', function() {
    if($('#contact_detail_v3').length == 1){
      $('#contact_detail_v3').validate({
        rules: {
          'applicant[phone]': {
            required: true,
            minlength: 12,
            maxlength: 12,
            phoneregex: true,
          }
        },
        messages: {
          'applicant[phone]': {
            required: 'Please enter mobile phone.',
            minlength: 'Your phone number must be 10 numeric digits only.',
            maxlength: 'Your phone number must be 10 numeric digits only.',
            phoneregex: 'Please enter valid mobile phone.',
          }
        }
      });
    }

    if($('#loan_detail_v3').length == 1){
      var min_loan_amount = parseInt($('#min_loan_amount').val())
      var max_loan_amount = parseInt($('#max_loan_amount').val())
      console.log(min_loan_amount)
      console.log("min_loan_amount")
      console.log(max_loan_amount)
      var minIncome = parseInt($('#min_income').val())
      $('#loan_detail_v3').validate({
        rules: {
          'applicant[loan_amount]': {
            required: true,
            number: true,
            min: min_loan_amount,
            max: max_loan_amount
          },
          'applicant[loan_purpose]': {
            required: true
          },
          'applicant[gross_annual_income]': {
            required: true,
            amountregx: true,
            minIncomeZero: true,
            maxlength: 9,
            annual_gross_max: true
          }
        },
        messages: {
          'applicant[loan_amount]': {
            required: 'Please enter requested amount.',
            number: 'Please enter requested amount.',
            min: 'Sorry, requested amount must be greater than ' + min_loan_amount + '.',
            max: 'Sorry, requested amount must be between ' + min_loan_amount + ' and ' + max_loan_amount + '.'
          },
          'applicant[loan_purpose]': {
            required: 'Please select Purpose.'
          },
          'applicant[gross_annual_income]': {
            required: 'Please enter gross income amount.',
            amountregx: 'Please enter gross income amount.',
            minIncomeZero: 'Sorry, annual gross income must be greater than zero.',
            annual_gross_max: 'Sorry, annual gross income must be less than 1000000.',
            maxlength: 'Sorry, annual gross income must be less than 1000000.'
          }
        }
      });
    }

    if($('#igw_ssn_detail_v3').length == 1){
      var minIncome = parseInt($('#min_income').val())
      $('#igw_ssn_detail_v3').validate({
        rules: {
          'applicant[birth_date]': {
            required: true
          },
          'applicant[ssn]': {
            required: true,
            ssnregex: true,
            minlength: 11,
            maxlength: 11
          },
          'applicant[gross_annual_income]': {
            required: true,
            amountregx: true,
            minIncomeZero: true,
            maxlength: 9
          }
        },
        messages: {
          'applicant[birth_date]': {
            required: 'Please select date of birth.'
          },
          'applicant[ssn]': {
            required: 'Please enter your social security number.',
            ssnregex: 'Sorry, SSN must be 9 numeric digits only.',
            maxlength: 'Sorry, SSN must be 9 numeric digits only.',
            minlength: 'Sorry, SSN must be 9 numeric digits only.'
          },
          'applicant[gross_annual_income]': {
            required: 'Please enter gross income amount.',
            amountregx: 'Please enter gross income amount.',
            minIncomeZero: 'Sorry, annual gross income must be greater than zero.',
            maxlength: 'Sorry, annual gross income must be less than 1000000.'
          }
        }
      });
    }


    if($('#identity_detail_v3').length == 1){
      $('#identity_detail_v3').validate({
        rules: {
          'applicant[birth_date]': {
            required: true
          },
          'applicant[ssn]': {
            required: true,
            ssnregex: true,
            minlength: 11,
            maxlength: 11
          }
        },
        messages: {
          'applicant[birth_date]': {
            required: 'Please select date of birth.'
          },
          'applicant[ssn]': {
            required: 'Please enter your social security number.',
            ssnregex: 'Sorry, SSN must be 9 numeric digits only.',
            maxlength: 'Sorry, SSN must be 9 numeric digits only.',
            minlength: 'Sorry, SSN must be 9 numeric digits only.'
          }
        }
      });
    }

    if($('#resident_detail_v3').length == 1){
      $('#resident_detail_v3').validate({
        rules: {
          'applicant[home_type]': {
            required: true
          },
          'applicant[form_of_house]':{
            required: true,
          },
          'applicant[monthly_housing_payment]': {
            required: true,
            amountregx: true,
            maxlength: 7
          },

         'applicant[time_at_address]': {
            required: true,
            // min: 0,
            // max: 99,
            // maxlength: 2
          },
          'applicant[time_at_address_month]': {
            required: true,
            // min: 0,
            // max: 12,
            // maxlength: 2
          },
           'applicant[previous_address]': {
            required: true,
            addressregx: true,
            poboxregex: true,
            minlength: 2,
            maxlength: 100
          },
          'applicant[previous_city]': {
            required: true,
            minlength: 2,
            cityregex: true,
            maxlength: 40
          },
          'applicant[previous_apt_unit]': {
            maxlength: 20,
            aptunit: true
          },
          'applicant[previous_state_id]': {
            required: true
          },
          'applicant[previous_zip]': {
            required: true,
            minlength: 5,
            maxlength: 5,
            zipregex: true
          }
        },
        messages: {
          'applicant[home_type]': {
            required: 'Please select Rent/Own.'
          },
          'applicant[form_of_house]':{
            required: 'Please select form of house'
          },
          'applicant[monthly_housing_payment]': {
            required: 'Please enter monthly housing payment.',
            amountregx: 'Please enter monthly housing payment.',
            maxlength: 'Sorry, amount entered must be less than 100,000.'
          },
          'applicant[time_at_address]': {
            required: 'Please select time at address (in years).'
            // min: 'Sorry, please enter time at address (in years) between 0 to 99.',
            // max: 'Sorry, please enter time at address (in years) between 0 to 99.',
            // maxlength: 'Sorry, please enter time at address (in years) between 0 to 99.'
          },
          'applicant[time_at_address_month]': {
            required: 'Please select time at address (in months).',
            // min: 'Sorry, please enter time at address (in months) between 0 to 12.',
            // max: 'Sorry, please enter time at address (in months) between 0 to 12.',
            // maxlength: 'Sorry, please enter time at address (in months) between 0 to 12.'
          },
          'applicant[previous_address]': {
            required: 'Please enter your street address.',
            poboxregex: 'Address must be a physical address',
            minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
            maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
            addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.'
          },
          'applicant[previous_city]': {
            required: 'Please enter city name.',
            minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
            maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
            cityregex: 'Please enter a valid city name.'
          },
          'applicant[previous_apt_unit]': {
            maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
            aptunit: 'Sorry, only letters(a-z), numbers, period(.), hyphen (-) & hash (#) are allowed.'
          },
          'applicant[previous_state_id]': {
            required: 'Please select State.'
          },
          'applicant[previous_zip]': {
            required: 'Please enter zip code.',
            zipregex: 'Please enter valid zip code.',
            minlength: 'Zip code must be 5 numeric digits only.',
            maxlength: 'Zip code must be 5 numeric digits only.'
          }
        }
      });
    }

    if($('#job_detail_v3').length == 1){
      $('#job_detail_v3').validate({
        rules: {
          'applicant[income_type]': {
            required: true
          },
          'applicant[year_at_job]': {
            required: true,
          },
          'applicant[previous_year_at_job]': {
            required: true,
            previous_year: true,
          },
          'applicant[employement_status]': {
            required: true
          },
          'applicant[employer_name]':{
            required: true,
            alphabetswithspace: true
          },
          'applicant[employer_phone]':{
            required: true,
            maxlength: 12,
            minlength:12,
            phoneregex: true,
          },
          'applicant[payment_frequency]': {
            required: true
          },
          'applicant[auto_payment_method]':{
            required: true
          }
        },
        messages: {
          'applicant[income_type]': {
            required: 'Please select income type salary/self employement.'
          },
          'applicant[year_at_job]': {
            required: 'Please enter years at job.'
          },
          'applicant[previous_year_at_job]': {
            required: 'Please enter Previous years at job.',
            previous_year: 'Please select at least 1 year.',
          },
          'applicant[employement_status]': {
            required: 'Please select employement status.'
          },
          'applicant[employer_name]': {
            required: 'Please enter Employer Name.',
            alphabetswithspace: 'Sorry, only alphabets are allowed'
          },
          'applicant[employer_phone]': {
            required: 'Please enter Employer Phone.',
            minlength: 'Your employer phone number must be 10 numeric digits only.',
            maxlength: 'Your employer phone number must be 10 numeric digits only.',
            phoneregex: 'Please enter valid employer phone.',
          },
          'applicant[payment_frequency]': {
            required: 'Please select your pay frequency.'
          },
          'applicant[auto_payment_method]': {
            required: 'Please select payment account type.'
          }
        }
      });
    }

    if($("#bank_detail_v3").length == 1){
      $("#bank_detail_v3").validate({
        rules: {
          "applicant[account_card_number]": {
            required: true,
            digits: true,
            accountcardregex: true,
            minlength: 16,
            maxlength: 16
          },
          "applicant[card_exp_month]": {
            required: true,
            minlength: 1,
            maxlength: 2,
            min: 1,
            max: 12
          },
          "applicant[card_exp_year]": {
            required: true,
            digits: true
          },
          "applicant[card_cvv]": {
            required: true,
            digits: true,
            minlength: 3,
            maxlength: 4
          },
          "applicant[bank_aba]": {
            required: true,
            digits: true,
            minlength: 9,
            maxlength: 9
          },
          "applicant[bank_account_number]": {
            required: true,
            digits: true,
            minlength: 5,
            maxlength: 25
          },
          "applicant[next_payment_date]": {
            required: true
          },
          'applicant[payment_frequency]': {
            required: true
          }
        },
        messages: {
          "applicant[account_card_number]": {
            required: "Please enter debit card number.",
            accountcardregex: "Please enter a valid debit card number.",
            digits: "Sorry, only numbers are allowed.",
            minlength: 'Sorry, debit card must be 16 digits.',
            maxlength: 'Sorry, debit card must be 16 digits.'
          },
          "applicant[card_exp_month]": {
            required: 'Please select card expiry month.',
            minlength: '',
            maxlength: '',
            min: 'Sorry, Card expiry month must be between 1 and 12.',
            max: 'Sorry, Card expiry month must be between 1 and 12.'
          },
          "applicant[card_exp_year]": {
            required: 'Please select card expiry year.',
            digits: 'Sorry, only numbers are allowed.'
          },
          "applicant[card_cvv]": {
            required: 'Please enter vaild CVV number.',
            digits: 'Sorry, only numbers are allowed.',
            maxlength: "Sorry, CVV number must be 3 or 4 digits.",
            minlength: "Sorry, CVV number must be 3 or 4 digits."
          },
          "applicant[bank_aba]": {
            required: "Please enter your bank's ABA (routing) number.",
            digits: 'Sorry, only numbers are allowed.',
            maxlength: "Sorry, the ABA number must be 9 characters long.",
            minlength: "Sorry, the ABA number must be 9 characters long."
          },
          "applicant[bank_account_number]": {
            required: 'Please enter bank account number.',
            digits: 'Sorry, only numbers are allowed.',
            maxlength: "Sorry, account number must be between 5 and 25 digits.",
            minlength: "Sorry, account number must be between 5 and 25 digits."
          },
          "applicant[next_payment_date]": {
            required: "Please select next payment date."
          },
          'applicant[payment_frequency]': {
            required: 'Please select your pay frequency.'
          }
        }
      });
    }

    if ($('#banking_information_v3').length == 1) {
      $('#banking_information_v3').validate({
          rules: {
          'applicant[routing_number]': {
            minlength: 9,
            maxlength: 9,
            required: true,
            digits: true,
            allZero: true
          },
          'applicant[account_number]': {
            required: true,
            digits: true,
            minlength: 6,
            maxlength: 25,
            allZero: true
          },
          'applicant[bank_name]':{
            maxlength: 256,
            required: true
          }
        },
        messages: {
          'applicant[routing_number]': {
            minlength: 'Sorry, routing number must be 9 digits',
            maxlength: 'Sorry, routing number must be 9 digits',
            digits: 'Sorry, only numbers are allowed.',
            allZero: 'Sorry, number is not valid.'

          },
          'applicant[account_number]': {
            maxlength: "Sorry, account number must be between 6 and 25 digits.",
            minlength: "Sorry, account number must be between 6 and 25 digits.",
            digits: 'Sorry, only numbers are allowed.',
            allZero: 'Sorry, number is not valid.'
          },
          'applicant[bank_name]':{
            maxlength: "Sorry, bank name must be less then 256 words"
          }
        }
      });
    }
    
    if($('#id_information').length == 1){
      $('#id_information').validate({
        rules: {
          'applicant[id_type]': {
            required: false
          },
          'applicant[id_number]': {
            required: true,
            maxlength: 65,
            alphanumeric: true
          },
          'applicant[expiration_date]': {
            required: true
          }
        },
        messages: {
          'applicant[id_type]': {
            required: 'Please select id type'
          },
          'applicant[id_number]': {
            required: 'Please enter id number',
            maxlength: 'ID number must be 10 alphanumeric only.',
            alphanumeric: 'Please use alphanumeric value only.',
          },
          'applicant[expiration_date]': {
            required: 'Please enter  expiration date.',
          }
        }
      });
    }

    if($('#co_id_information').length == 1){
      $('#co_id_information').validate({
        rules: {
          'applicant[co_id_type]': {
            required: false
          },
          'applicant[co_id_number]': {
            required: true,
            maxlength: 65,
            alphanumeric: true
          },
          'applicant[co_expiration_date]': {
            required: true
          }
        },
        messages: {
          'applicant[co_id_type]': {
            required: 'Please select id type'
          },
          'applicant[co_id_number]': {
            required: 'Please enter id number',
            maxlength: 'ID number must be 10 alphanumeric only.',
            alphanumeric: 'Please use alphanumeric value only.',
          },
          'applicant[co_expiration_date]': {
            required: 'Please enter  expiration date.',
          }
        }
      });
    }

    if($("#ucfs_form_v3").length == 1){
      $("#ucfs_form_v3").validate({
          rules: {
          'applicant[form_of_house]': {
            required: true
          },
          'applicant[employer_phone]':{
            required: 'Please enter Employer Phone.',
            maxlength: 12,
            minlength:12,
            phoneregex: true,
          },
          'applicant[previous_year_at_job]': {
            required: true,
            past_year: true,
          },
           'applicant[previous_address]': {
            required: true,
            addressregx: true,
            poboxregex: true,
            minlength: 2,
            maxlength: 100
          },
          'applicant[previous_city]': {
            required: true,
            minlength: 2,
            cityregex: true,
            maxlength: 40
          },
          'applicant[previous_apt_unit]': {
            maxlength: 20,
            aptunit: true
          },
          'applicant[previous_state_id]': {
            required: true
          },
          'applicant[previous_zip]': {
            required: true,
            minlength: 5,
            maxlength: 5,
            zipregex: true
          },
          'applicant[auto_payment_method]':{
            required: true
          }
        },
        messages: {
          'applicant[form_of_house]': {
            required: 'Please select Housing Type.'
          },
          'applicant[employer_phone]': {
            required: 'Please enter Employer Phone.',
            minlength: 'Your employer phone number must be 10 numeric digits only.',
            maxlength: 'Your employer phone number must be 10 numeric digits only.',
            phoneregex: 'Please enter valid employer phone.',
          },
          'applicant[previous_year_at_job]': {
            required: 'Please enter Previous years at job.',
            past_year: 'Please select at least 1 year.',
          },
          'applicant[previous_address]': {
            required: 'Please enter your street address.',
            poboxregex: 'Address must be a physical address',
            minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
            maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
            addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.'
          },
          'applicant[previous_city]': {
            required: 'Please enter city name.',
            minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
            maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
            cityregex: 'Please enter a valid city name.'
          },
          'applicant[previous_apt_unit]': {
            maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
            aptunit: 'Sorry, only letters(a-z), numbers, period(.), hyphen (-) & hash (#) are allowed.'
          },
          'applicant[previous_state_id]': {
            required: 'Please select State.'
          },
          'applicant[previous_zip]': {
            required: 'Please enter zip code.',
            zipregex: 'Please enter valid zip code.',
            minlength: 'Zip code must be 5 numeric digits only.',
            maxlength: 'Zip code must be 5 numeric digits only.'
          },
          'applicant[auto_payment_method]': {
            required: 'Please select payment account type.'
          }
        }
      });
    }

    if($("#income_detail_v3").length == 1){
      $("#income_detail_v3").validate({
        rules: {
          "applicant[next_payment_date]": {
            required: true
          },
          'applicant[auto_payment_method]':{
            required: true
          }
        },
        messages: {
          "applicant[next_payment_date]": {
            required: "Please select next payment date."
          },
          'applicant[auto_payment_method]': {
            required: 'Please select payment account type.'
          }
        }
      });
    }

    if($("#co_basic_detail_v3").length == 1){
      $("#co_basic_detail_v3").validate({
        rules: {
         "applicant[co_first_name]": {
            required: true,
            lastNameValidation: true,
            minlength: 2,
            maxlength: 40
          },
          "applicant[co_last_name]": {
            required: true,
            lastNameValidation: true,
            minlength: 2,
            maxlength: 40
          },
          "applicant[co_address]": {
            required: true,
            addressregx: true,
            poboxregex: true,
            minlength: 2,
            maxlength: 100
          },
          "applicant[co_city]": {
            required: true,
            minlength: 2,
            maxlength: 40,
            cityregex: true
          },
          "applicant[co_state_id]":{
            required: true
          },
          "applicant[co_apt_unit]": {
            maxlength: 20,
            aptunit: true
          },
          "applicant[co_zip]": {
            required: true,
            minlength: 5,
            maxlength: 5,
            zipregex: true
          },
          "applicant[co_phone]": {
            required: true,
            maxlength: 12,
            minlength:12,
            phoneregex: true,
          },
          "applicant[co_email]": {
            required: true,
            emailregex: true,
            minlength: 2,
            maxlength: 100
          }
        },
        messages: {
          "applicant[co_first_name]": {
            lastNameValidation: 'Please use alphabets only.',
            required: 'Please enter first name.',
            minlength: 'Sorry, your first name must be between 2 and 40 characters long.',
            maxlength: 'Sorry, your first name must be between 2 and 40 characters long.'
          },
          "applicant[co_last_name]": {
            lastNameValidation: 'Please use alphabets only.',
            required: 'Please enter last name.',
            minlength: 'Sorry, your last name must be between 2 and 40 characters long.',
            maxlength: 'Sorry, your last name must be between 2 and 40 characters long.'
          },
          "applicant[co_address]": {
            required: 'Please enter your street address.',
            poboxregex: 'Address must be a physical address.',
            minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
            maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
            addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.'
          },
          "applicant[co_city]": {
            required: 'Please enter city name.',
            minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
            maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
            cityregex: 'Please enter a valid city name.'
          },
          "applicant[co_state_id]": {
            required: 'Please select State.'
          },
          "applicant[co_apt_unit]": {
            maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
            aptunit: 'Sorry, only letters(a-z), numbers, period(.), hyphen (-), & hash (#) are allowed.'
          },
          "applicant[co_zip]": {
            required: 'Please enter zip code.',
            minlength: 'Zip code must be 5 numeric digits only.',
            maxlength: 'Zip code must be 5 numeric digits only.',
            zipregex: 'Please enter valid zip code.',
          },
          "applicant[co_email]": {
            required: 'Please enter email address.',
            emailregex: 'Please enter valid email address.',
            maxlength: 'Please enter valid email address.',
            minlength: 'Please enter valid email address.'
          },
          "applicant[co_phone]": {
            required: 'Please enter mobile phone.',
            minlength: 'Your phone number must be 10 numeric digits only.',
            maxlength: 'Your phone number must be 10 numeric digits only.',
            phoneregex: 'Please enter valid mobile phone.',
          }
        }
      });

      $('#applicant_co_email').rules('add', {
        primarynotallow: true,
        messages: {
          primarynotallow: "Email needs to be different from primary applicant."
        }
      });

      $.validator.addMethod("primarynotallow", function(value, element) {
        primary_email = $('#primary_email').val();
        return this.optional(element) || (primary_email != value);
      });
    }

    if($("#co_loan_detail_v3").length == 1){
      var minIncome = parseInt($('#min_income').val())
      $("#co_loan_detail_v3").validate({
        rules: {
          "applicant[co_gross_annual_income]": {
            required: true,
            amountregx: true,
            maxlength: 9,
            minIncomeZero: true,
            annual_gross_max: true
          }
        },
       messages: {
        "applicant[co_gross_annual_income]": {
            required: 'Please enter gross income amount.',
            amountregx: 'Please enter gross income amount.',
            minIncomeZero: 'Sorry, annual gross income must be greater than zero.',
            maxlength: 'Sorry, annual gross income must be less than 1000000.',
            annual_gross_max: 'Sorry, annual gross income must be less than 1000000.'
          }
        }
      });
    }

    if($("#co_identity_detail_v3").length == 1){
      $("#co_identity_detail_v3").validate({
        rules: {
          "applicant[co_birth_date]": {
            required: true,
          },
         "applicant[co_ssn]" :{
            required: true,
            ssnregex: true
         },
         "applicant[co_gross_annual_income]": {
            required: true,
            amountregx: true,
            minIncomeZero: true,
            maxlength: 9,
            annual_gross_max: true
          }
       },
       messages: {
          "applicant[co_birth_date]": {
            required: 'Please select date of birth.'
          },
          "applicant[co_ssn]": {
            required: 'Please enter your social security number.',
            ssnregex: 'Sorry, SSN must be 9 numeric digits only.',
            maxlength: 'Sorry, SSN must be 9 numeric digits only.',
            minlength: 'Sorry, SSN must be 9 numeric digits only.'
          },
          "applicant[co_gross_annual_income]": {
            required: 'Please enter gross income amount.',
            amountregx: 'Please enter gross income amount.',
            minIncomeZero: 'Sorry, annual gross income must be greater than zero.',
            maxlength: 'Sorry, annual gross income must be less than 1000000.',
            annual_gross_max: 'Sorry, annual gross income must be less than 1000000.'
          },
       }
      });

       $('#applicant_co_ssn').rules('add', {
        primarynotallow: true,
        messages: {
          primarynotallow: "SSN needs to be different from primary applicant."
        }
      });

      $.validator.addMethod("primarynotallow", function(value, element) {
        primary_ssn = $('#primary_ssn').val();
        return this.optional(element) || (primary_ssn != value);
      });
    }

    if($("#co_resident_detail_v3").length == 1){
      $("#co_resident_detail_v3").validate({
          rules: {
          'applicant[co_home_type]': {
            required: true
          },
          'applicant[co_form_of_house]':{
            required: true,
          },
          'applicant[co_monthly_housing_payment]': {
            required: true,
            amountregx: true,
            maxlength: 7
          },

         'applicant[co_time_at_address]': {
            required: true,
            // min: 0,
            // max: 99,
            // maxlength: 2
          },
          'applicant[co_time_at_address_month]': {
            required: true,
            // min: 0,
            // max: 12,
            // maxlength: 2
          },
           'applicant[co_previous_address]': {
            required: true,
            addressregx: true,
            poboxregex: true,
            minlength: 2,
            maxlength: 100
          },
          'applicant[co_previous_city]': {
            required: true,
            minlength: 2,
            cityregex: true,
            maxlength: 40
          },
          'applicant[co_previous_apt_unit]': {
            maxlength: 20,
            aptunit: true
          },
          'applicant[co_previous_state_id]': {
            required: true
          },
          'applicant[co_previous_zip]': {
            required: true,
            minlength: 5,
            maxlength: 5,
            zipregex: true
          }
        },
        messages: {
          'applicant[co_home_type]': {
            required: 'Please select Rent/Own.'
          },
          'applicant[co_form_of_house]':{
            required: 'Please select form of house'
          },
          'applicant[co_monthly_housing_payment]': {
            required: 'Please enter monthly housing payment.',
            amountregx: 'Please enter monthly housing payment.',
            maxlength: 'Sorry, amount entered must be less than 100,000.'
          },
          'applicant[co_time_at_address]': {
            required: 'Please select time at address (in years).'
            // min: 'Sorry, please enter time at address (in years) between 0 to 99.',
            // max: 'Sorry, please enter time at address (in years) between 0 to 99.',
            // maxlength: 'Sorry, please enter time at address (in years) between 0 to 99.'
          },
          'applicant[co_time_at_address_month]': {
            required: 'Please select time at address (in months).',
            // min: 'Sorry, please enter time at address (in months) between 0 to 12.',
            // max: 'Sorry, please enter time at address (in months) between 0 to 12.',
            // maxlength: 'Sorry, please enter time at address (in months) between 0 to 12.'
          },
          'applicant[co_previous_address]': {
            required: 'Please enter your street address.',
            poboxregex: 'Address must be a physical address',
            minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
            maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
            addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.'
          },
          'applicant[co_previous_city]': {
            required: 'Please enter city name.',
            minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
            maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
            cityregex: 'Please enter a valid city name.'
          },
          'applicant[co_previous_apt_unit]': {
            maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
            aptunit: 'Sorry, only letters(a-z), numbers, period(.), hyphen (-) & hash (#) are allowed.'
          },
          'applicant[co_previous_state_id]': {
            required: 'Please select State.'
          },
          'applicant[co_previous_zip]': {
            required: 'Please enter zip code.',
            zipregex: 'Please enter valid zip code.',
            minlength: 'Zip code must be 5 numeric digits only.',
            maxlength: 'Zip code must be 5 numeric digits only.'
          }
        }
      });
    }

    if($('#co_job_detail_v3').length == 1){
      $('#co_job_detail_v3').validate({
        rules: {
          'applicant[co_income_type]': {
            required: true
          },
          'applicant[co_year_at_job]': {
            required: true,
            min: 0,
            max: 99,
            maxlength: 2
          },
          'applicant[co_employement_status]': {
            required: true
          },
          'applicant[co_employer_name]':{
            required: true,
            alphabetswithspace: true
          },
          'applicant[co_employer_phone]':{
            required: true,
            maxlength: 12,
            minlength:12,
            phoneregex: true,
          },
          'applicant[co_payment_frequency]': {
            required: true
          },
          'applicant[co_previous_year_at_job]': {
            required: true,
            co_previous_year: true,
          },
          'applicant[co_auto_payment_method]':{
            required: true
          }
        },
        messages: {
          'applicant[co_income_type]': {
            required: 'Please select income type salary/self employement.'
          },
          'applicant[co_year_at_job]': {
            required: 'Please enter years at job.',
            min: 'Sorry, please enter years at job between 0 to 99.',
            max: 'Sorry, please enter years at job between 0 to 99.',
            maxlength: 'Sorry, please enter years at job between 0 to 99.',
          },
          'applicant[co_employement_status]': {
            required: 'Please select employement status.'
          },
          'applicant[co_employer_name]': {
            required: 'Please enter Employer Name.',
            alphabetswithspace: 'Sorry, only alphabets are allowed'
          },
          'applicant[co_employer_phone]': {
            required: 'Please enter Employer Phone.',
            minlength: 'Your employer phone number must be 10 numeric digits only.',
            maxlength: 'Your employer phone number must be 10 numeric digits only.',
            phoneregex: 'Please enter valid employer phone.',
          },
          'applicant[co_payment_frequency]': {
            required: 'Please select your pay frequency.'
          },
          'applicant[co_previous_year_at_job]': {
            required: 'Please enter Previous years at job.',
            co_previous_year: 'Please select at least 1 year.',
          },
          'applicant[co_auto_payment_method]':{
            required: 'Please select auto payment method.'
          }
        }
      });
    }

    if($("#co_income_detail_v3").length == 1){
      $("#co_income_detail_v3").validate({
        rules: {
          "applicant[co_next_payment_date]": {
            required: true
          },
          'applicant[co_auto_payment_method]':{
            required: true
          }
        },
        messages: {
          "applicant[co_next_payment_date]": {
            required: "Please select next payment date."
          },
          'applicant[co_auto_payment_method]': {
            required: 'Please select auto payment method.'
          }
        }
      });
    }

    if($("#co_ucfs_form_v3").length == 1){
      $("#co_ucfs_form_v3").validate({
          rules: {
          'applicant[co_form_of_house]': {
            required: true
          },
           'applicant[co_employer_phone]':{
            required: 'Please enter Employer Phone.',
            maxlength: 12,
            minlength:12,
            phoneregex: true,
          },
           'applicant[co_previous_address]': {
            required: true,
            addressregx: true,
            poboxregex: true,
            minlength: 2,
            maxlength: 100
          },
          'applicant[co_previous_city]': {
            required: true,
            minlength: 2,
            cityregex: true,
            maxlength: 40
          },
          'applicant[co_previous_apt_unit]': {
            maxlength: 20,
            aptunit: true
          },
          'applicant[co_previous_state_id]': {
            required: true
          },
          'applicant[co_previous_zip]': {
            required: true,
            minlength: 5,
            maxlength: 5,
            zipregex: true
          },
          'applicant[co_previous_year_at_job]': {
            required: true,
            co_past_year: true,
          },
          'applicant[co_auto_payment_method]':{
            required: true
          }
        },
        messages: {
          'applicant[co_form_of_house]': {
            required: 'Please select Housing Type.'
          },
          'applicant[co_employer_phone]': {
            required: 'Please enter Employer Phone.',
            minlength: 'Your employer phone number must be 10 numeric digits only.',
            maxlength: 'Your employer phone number must be 10 numeric digits only.',
            phoneregex: 'Please enter valid employer phone.',
          },
          'applicant[co_previous_address]': {
            required: 'Please enter your street address.',
            poboxregex: 'Address must be a physical address',
            minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
            maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
            addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.'
          },
          'applicant[co_previous_city]': {
            required: 'Please enter city name.',
            minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
            maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
            cityregex: 'Please enter a valid city name.'
          },
          'applicant[co_previous_apt_unit]': {
            maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
            aptunit: 'Sorry, only letters(a-z), numbers, period(.), hyphen (-) & hash (#) are allowed.'
          },
          'applicant[co_previous_state_id]': {
            required: 'Please select State.'
          },
          'applicant[co_previous_zip]': {
            required: 'Please enter zip code.',
            zipregex: 'Please enter valid zip code.',
            minlength: 'Zip code must be 5 numeric digits only.',
            maxlength: 'Zip code must be 5 numeric digits only.'
          },
          'applicant[co_previous_year_at_job]': {
            required: 'Please enter Previous years at job.',
            co_past_year: 'Please select at least 1 year.',
          },
          'applicant[co_auto_payment_method]': {
            required: 'Please select auto payment method.'
          }
        }
      });
    }

    if($("#igw_product_description_v3").length == 1){
      $('#igw_product_description_v3').validate({
        rules: {
          'applicant[product_discription]': {
            required: true,
            minlength: 2,
            maxlength: 100
          }
        },
        messages: {
          'applicant[product_discription]': {
            required: 'Please enter product description.',
            minlength: 'The product description must be between 2 and 100 characters long.',
            maxlength: 'The product description must be between 2 and 100 characters long.'
          }
        }
      })
    }

    if($('#stip_form').length > 0){
      referenceDocumentFormValidation();
      $('#stip input').keyup(function() {
        validation_checker()
      })
    }

    if($('#reset_password_form_admin').length == 1){
      $("#reset_password_form_admin").validate({
        rules: {
          "admin_user[password]": {
            required: true,
            passwordregex: true
          },
          "admin_user[password_confirmation]": {
            required: true,
            passwordregex: true
          }
        },
        messages: {
          "admin_user[password]": {
            required: "The password is required and can\'t be empty",
            passwordregex: 'The password must have 8 or more characters with a mix of letters, numbers & symbols.'
          },
          "admin_user[password_confirmation]": {
            required: "The confirm password is required and can\'t be empty",
            passwordregex: 'The password must have 8 or more characters with a mix of letters, numbers & symbols.'
          }
        },
      })
    }

    $.validator.addMethod("passwordregex", function(value, element) {
      return this.optional(element) || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/i.test(value);
    });
    $.validator.addMethod("accountcardregex", function(value, element) {
      return this.optional(element) || /^[0-9]*$/.test(value);
    });
    $.validator.addMethod("ssnregex", function(value, element) {
      return this.optional(element) || /^(\+\d{1,1}\s)?\(?\d{3}\)?[\s.-]\d{2}[\s.-]\d{4}$/.test(value);
    });
    $.validator.addMethod("phoneregex", function(value, element) {
      return this.optional(element) || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value);
    });
    $.validator.addMethod("alphabetsnspace", function(value, element) {
      return this.optional(element) || /^[a-zA-Z]+$/.test(value);
    });

    $.validator.addMethod("lastNameValidation", function(value, element) {
      return this.optional(element) || /^([a-zA-Z., '-])*[a-zA-Z., '-]+$/.test(value);
    });

    $.validator.addMethod("alphabetswithspace", function(value, element) {
      return this.optional(element) || /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/.test(value);
    });
    $.validator.addMethod("alphanumeric", function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9 ]+$/.test(value);
    });
    $.validator.addMethod("addressregx", function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9 .,-]+$/.test(value);
    });
    $.validator.addMethod("amountregx", function(value, element) {
      return this.optional(element) || /^[0-9,]+$/.test(value);
    });

    $.validator.addMethod("minIncome", function(value, element) {
      var incomeAmount = $('#min_income').val()
      if(incomeAmount.length > 0){
        incomeAmount = Number(incomeAmount)
      }else{
        incomeAmount = 10000
      }
      return this.optional(element) || Number(value.replace(/,/g, '')) >= incomeAmount;
    });

    $.validator.addMethod("minIncomeZero", function(value, element) {
      // var incomeAmount = 1 // $('#min_income').val()
      // if(incomeAmount > 0){
      //   incomeAmount = Number(incomeAmount)
      // }else{
      //   incomeAmount = 10000
      // }
      return this.optional(element) || Number(value.replace(/,/g, '')) >= 1;
    });

    $.validator.addMethod("emailregex", function(value, element) {
      return this.optional(element) || /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(value);
    });

    $.validator.addMethod("cityregex", function(value, element) {
      return this.optional(element) || /^[a-zA-Z ]+(?:[- ][a-zA-Z ]+)*$/.test(value);
    });

    $.validator.addMethod("zipregex", function(value, element) {
      return this.optional(element) || /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    });
    $.validator.addMethod("aptunit", function(value, element) {
       return this.optional(element) || /^[a-zA-Z0-9 ,!@#\$%\^\&*\)\(+=._'-]+$/.test(value);
    });

    $.validator.addMethod("poboxregex", function(value, element) {
      return this.optional(element) || !/\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box[0-9]{0,10})\b/i.test(value);
    });

    $.validator.addMethod("previous_year", function(value, element) {
        current_year = $('#applicant_year_at_job').val();
        return this.optional(element) || (current_year < 3 && value > 0);
    });

    $.validator.addMethod("co_previous_year", function(value, element) {
        current_year = $('#applicant_co_year_at_job').val();
        return this.optional(element) || (current_year < 3 && value > 0);
    });

    $.validator.addMethod("past_year", function(value, element) {
        current_year = $('#applicant_previous_year_at_job').val();
        return this.optional(element) || current_year > 0;
    });

    $.validator.addMethod("co_past_year", function(value, element) {
        current_year = $('#applicant_co_previous_year_at_job').val();
        return this.optional(element) || current_year > 0;
    });

    $.validator.addMethod("annual_gross_max", function(value, element) {
      return this.optional(element) || Number(value.replace(/,/g, ''))<= 1000000;
    });

    $('#apply-forms').mouseover(function(){validation_checker()})
    $(formIdsText(applicantForms)).on('keyup change', function(){
      validation_checker()
    });
     
    validation_checker()

    $('#applicant_first_name, #applicant_last_name, #applicant_co_first_name, #applicant_co_last_name, #applicant_city, #applicant_co_city, #applicant_address, #applicant_co_address, #applicant_co_apt_unit, #applicant_apt_unit').on('blur', function() {
      var inputText = $(this).val();
      // Replace multiple spaces with a single space
      var cleanedText = inputText.replace(/\s+/g, ' ');
      // Update the input field value
      $(this).val(cleanedText);
       
      var trimmedText = $(this).val().trim();
      $(this).val(trimmedText);
    });

    $('#applicant_co_apt_unit, #applicant_apt_unit').on('blur', function() {
      var inputText = $(this).val();
      // Replace multiple spaces with a single space
      var cleanedText = inputText.replace(/\s+/g, '');
      // Update the input field value
      $(this).val(cleanedText);
      var trimmedText = $(this).val().trim();
      $(this).val(trimmedText);
    });

    $('#applicant_savings, #applicant_checking').change(function() {
      var listDisplay = { 'debit_card': 'Debit Card', 'credit_card': 'Credit Card', 'savings': 'Savings', 'checking': 'Checking' }
      var list = ['debit_card', 'credit_card']

      if($('#applicant_checking').is(":checked")){
        list.push('checking')
      }

      if($('#applicant_savings').is(":checked")){
        list.push('savings')
      }

      $("#applicant_auto_payment_method").html('');
      $("#applicant_auto_payment_method").append('<option value="">Select</option>');
      $.each(list, function(index, item){
        $("#applicant_auto_payment_method").append( $('<option>', { value: item, text: listDisplay[item] }))
      });
    });

    $('#applicant_employement_status').on('change', function(){
      jobAtYearDynamicShow();
      PreviousjobAtYearDynamicShow();
    })

    $('#applicant_year_at_job').on('change', function(){
      PreviousjobAtYearDynamicShow();
    });

    $('#applicant_co_year_at_job').on('change', function(){
      CoPreviousjobAtYearDynamicShow();
    });

    $('#applicant_co_employement_status').on('change', function(){
      CojobAtYearDynamicShow();
      CoPreviousjobAtYearDynamicShow();
    })

    $('#applicant_time_at_address, #applicant_time_at_address_month').change(function(){
     let time_at_address_in_year = $('#applicant_time_at_address').val()
     let time_at_address_in_month = $('#applicant_time_at_address_month').val()
      if (time_at_address_in_month.length > 0 && time_at_address_in_year.length > 0 && time_at_address_in_year <= 0 && time_at_address_in_month >= 0){
        $('#previous_address').show()
        $('#applicant_previous_address').attr('required', true);
        $('#applicant_previous_city').attr('required', true);
        $('#applicant_previous_state_id').attr('required', true);
        $('#applicant_previous_zip').attr('required', true);

      }else{
        $('#previous_address').hide()
        $('#applicant_previous_address').attr('required', false);
        $('#applicant_previous_city').attr('required', false);
        $('#applicant_previous_state_id').attr('required', false);
        $('#applicant_previous_zip').attr('required', false);
      }

      if(time_at_address_in_year.length > 0 && time_at_address_in_year > 0){
        $('#applicant_time_at_address_month').rules('add', {
          min: 0
        });
      }else{
        $('#applicant_time_at_address_month').rules('add', {
          min: 1,
          messages: {
            min: 'Sorry, months and years both can not be zero.'
          }
        });
      }
    })

    $('#applicant_co_time_at_address, #applicant_co_time_at_address_month').change(function(){
     let co_time_at_address_in_year = $('#applicant_co_time_at_address').val()
     let co_time_at_address_in_month = $('#applicant_co_time_at_address_month').val()
      if (co_time_at_address_in_month.length > 0 && co_time_at_address_in_year.length > 0 && co_time_at_address_in_year <= 0 && co_time_at_address_in_month >= 0){
        $('#co_previous_address').show()
        $('#applicant_co_previous_address').attr('required', true);
        $('#applicant_co_previous_city').attr('required', true);
        $('#applicant_co_previous_state_id').attr('required', true);
        $('#applicant_co_previous_zip').attr('required', true);


      }else{
        $('#co_previous_address').hide()
        $('#applicant_co_previous_address').attr('required', false);
        $('#applicant_co_previous_city').attr('required', false);
        $('#applicant_co_previous_state_id').attr('required', false);
        $('#applicant_co_previous_zip').attr('required', false);
      }

      if(co_time_at_address_in_month.length > 0  && co_time_at_address_in_year > 0){
        $('#applicant_co_time_at_address_month').rules('add', {
          min: 0
        });
      }else{
        $('#applicant_co_time_at_address_month').rules('add', {
          min: 1,
          messages: {
            min: 'Sorry, months and years both can not be zero.'
          }
        });
      }
    })
  });

  $('#accept_payment_plan_form').validate({
    rules: {
      'applicant[product_discription]': {
        required: true,
        minlength: 2,
        maxlength: 100
      }
    },
    messages: {
      'applicant[product_discription]': {
        required: 'Please enter product description.',
        minlength: 'The product description must be between 2 and 100 characters long.',
        maxlength: 'The product description must be between 2 and 100 characters long.'
      }
    }
  })

  $('.offer-control').on('click', function() {
    $('.document_upload_form input').change(function(){validation_checker()})
    $('.validation_checker_on').mouseover(function(){validation_checker()})

    $('.document_upload_file').change(function(){
      var file_name = $(this).get(0).files[0].name
      $(this).prev().text(file_name)
    });
  });


  $('.offer-control').on('click', function() {
    $('.document_upload_dtm_form input').change(function(){validation_checker()})
    $('.validation_checker_on').mouseover(function(){validation_checker()})

    $('.document_upload_file').change(function(){
      var file_name = $(this).get(0).files[0].name
      $(this).prev().text(file_name)
    });
  });

  $('#applicant_product_amount').on('keyup change',function(){
    target_down_payment = $('#product_detail_form').data('target-down-payment');
    minimum_down_payment = $('#product_detail_form').data('minimum-down-payment');
    maximum_down_payment = $('#applicant_product_amount').val()
    maximum_down_payment = parseFloat(maximum_down_payment).toFixed(2) - 0.1
    targetProductAmount = $('#applicant_product_amount').val()*target_down_payment/100
    productDownPayment = $('#applicant_product_amount').val()*minimum_down_payment/100
    $('#applicant_deposit').val(targetProductAmount);
    $('#applicant_deposit').keyup();
  });

  $('#applicant_deposit').on('keyup change',function(){
    $('#applicant_deposit').attr('max', maximum_down_payment.toFixed(2))
    $('#applicant_deposit').attr('min', productDownPayment.toFixed(2))

    $('#applicant_deposit').rules('add', {
      max: maximum_down_payment,
      min: productDownPayment,
      messages: {
        max: 'Sorry, Down Payment entered must be less purchase price.',
        min: 'Sorry, Down Payment entered must be greater than or equal to ' + productDownPayment.toFixed(0) + '.'
      }
    });
  })
});

window.maxLimit = function(element){
  let amount = $(element).val()
  if(amount > 100000000){
    amount_str = (amount).toLocaleString('fullwide', {useGrouping:false})
    $(element).val(amount_str.slice(0,9))
  }
}

window.formIdsText = function(applicantFormIds){
  text = []
  $.each(applicantFormIds, function(index, form){
    text.push('#' + form)
    text.push('#' + form + ' input ')
  })
  return text.join(', ');
}

window.removedNotRequiredFieldValidation = function(notRequiredFieldArray){
  // renoved Jquery validation unrequired according Lender.
  $.each( notRequiredFieldArray, function(index, field) {
    $('#applicant_' + field).rules('add', {
      required: false,
      messages: {
        required: ''
      }
    });

    // removed required property from HTML tags
    $('#applicant_' + field).prop('required', false)
  })
}

window.setEndDateNextPaymentDate = function(endDay){
  if($('#applicant_next_payment_date').length == 1){
    $('#applicant_next_payment_date').datepicker('setStartDate', '+1D');
    $('#applicant_next_payment_date').datepicker('setEndDate', endDay);
  }
}

window.PreviousjobAtYearDynamicShow = function() {
  let year_at_job = $('#applicant_year_at_job').val()
  let year_at_job_visible = $('#applicant_year_at_job:visible').length
  if(year_at_job != undefined && year_at_job.length > 0 && year_at_job < 3 && year_at_job_visible > 0){
      $('#applicant_previous_year_at_job').parent().parent().show()
      // $('#app`licant_previous_year_at_job').val('')
    }else{
      $('#applicant_previous_year_at_job').parent().parent().hide()
      // $('#app`licant_previous_year_at_job').val('')
    }
}

window.CoPreviousjobAtYearDynamicShow = function() {
  let co_year_at_job = $('#applicant_co_year_at_job').val()
  let co_year_at_job_visible = $('#applicant_co_year_at_job:visible').length
  if(co_year_at_job.length != undefined && co_year_at_job.length > 0 && co_year_at_job < 3 && co_year_at_job_visible > 0){
      $('#applicant_co_previous_year_at_job').parent().parent().show()
    }else{
      $('#applicant_co_previous_year_at_job').parent().parent().hide()
    }
}

window.jobAtYearDynamicShow = function(){
  let incomeSource = $('#applicant_employement_status').val()
  if(incomeSource == 'employed' || incomeSource == 'self_employed' || incomeSource == 'military'){
    $('#applicant_year_at_job').parent().parent().show()
    $('#applicant_year_at_job').val('')

    if(incomeSource == 'employed' || incomeSource == 'self_employed' || incomeSource == 'military') {
      $('#applicant_employer_name').parent().parent().show()
      $('#applicant_employer_name').val('')
      $('#applicant_employer_name').rules('add', {
        required: true,
        messages: {
          required: 'Please enter Employer Name.'
        }
      });
      $('#applicant_employer_name').attr('required', true)
      
      $('#applicant_employer_phone').parent().parent().show()
      $('#applicant_employer_phone').val('')
      $('#applicant_employer_phone').rules('add', {
        required: true,
        messages: {
          required: 'Please enter Employer Phone.'
        }
      });
      $('#applicant_employer_phone').attr('required', true)
    }
    else{
      $('#applicant_employer_name').rules('add', {
        required: false,
        messages: {
          required: ''
        }
      });
      $('#applicant_employer_name').attr('required', false)
      $('#applicant_employer_name').parent().parent().hide()

      $('#applicant_employer_phone').rules('add', {
        required: false,
        messages: {
          required: ''
        }
      });
      $('#applicant_employer_phone').attr('required', false)
      $('#applicant_employer_phone').parent().parent().hide()
    }
  }
  else{
    $('#applicant_year_at_job').val(0)
    $('#applicant_year_at_job').parent().parent().hide()
    $('#applicant_employer_name').parent().parent().hide()

    $('#applicant_employer_name').rules('add', {
      required: false,
      messages: {
        required: ''
      }
    });
    $('#applicant_employer_name').attr('required', false)
    $('#applicant_employer_name').parent().parent().hide()


    $('#applicant_employer_phone').parent().parent().hide()
    $('#applicant_employer_phone').rules('add', {
      required: false,
      messages: {
        required: ''
      }
    });
    $('#applicant_employer_phone').attr('required', false)
    $('#applicant_employer_phone').parent().parent().hide()
  }
}
window.CojobAtYearDynamicShow = function(){
  let incomeSource = $('#applicant_co_employement_status').val()
  if(incomeSource == 'co_employed' || incomeSource == 'co_self_employed' || incomeSource == 'co_military'){
    $('#applicant_co_year_at_job').parent().parent().show()
    $('#applicant_co_year_at_job').val('')

    if(incomeSource == 'co_employed' || incomeSource == 'co_self_employed' || incomeSource == 'co_military') {
      $('#applicant_co_employer_name').parent().parent().show()
      $('#applicant_co_employer_name').val('')

      $('#applicant_co_employer_name').rules('add', {
        required: true,
        messages: {
          required: 'Please enter Employer Name.'
        }
      });
      $('#applicant_co_employer_name').attr('required', true)

      $('#applicant_co_employer_phone').parent().parent().show()
      $('#applicant_co_employer_phone').val('')

      $('#applicant_co_employer_phone').rules('add', {
        required: true,
        messages: {
          required: 'Please enter Employer Phone.'
        }
      });
      $('#applicant_co_employer_phone').attr('required', true)
    }
    else{
      $('#applicant_co_employer_name').rules('add', {
        required: false,
        messages: {
          required: ''
        }
      });
      $('#applicant_co_employer_name').attr('required', false)
      $('#applicant_co_employer_name').parent().parent().hide()

      $('#applicant_co_employer_phone').rules('add', {
        required: false,
        messages: {
          required: ''
        }
      });
      $('#applicant_co_employer_phone').attr('required', false)
      $('#applicant_co_employer_phone').parent().parent().hide()
    }
  }
  else{
    $('#applicant_co_year_at_job').val(0)
    $('#applicant_co_year_at_job').parent().parent().hide()
    $('#applicant_co_employer_name').parent().parent().hide()

    $('#applicant_co_employer_name').rules('add', {
      required: false,
      messages: {
        required: ''
      }
    });
    $('#applicant_co_employer_name').attr('required', false)
    $('#applicant_co_employer_name').parent().parent().hide()

    $('#applicant_co_employer_phone').rules('add', {
      required: false,
      messages: {
        required: ''
      }
    });
    $('#applicant_co_employer_phone').attr('required', false)
    $('#applicant_co_employer_phone').parent().parent().hide()
  }
}

window.trimInput = function(element) {
  let ipv = element.value
  let trimIpv = ipv.trim()
  if(trimIpv != ipv){
    $(element).val(trimIpv)
  }
}

window.referenceDocumentFormValidation = function(){

  if($('#pri_number_of_reference').length > 0){
    var numberOfReference = $('#pri_number_of_reference').val();
    referenceDocumentFormValidationByType('pri', numberOfReference)
  }

  if($('#co_number_of_reference').length > 0){
    var numberOfReference = $('#co_number_of_reference').val();
    referenceDocumentFormValidationByType('co', numberOfReference)
  }

}



window.referenceDocumentFormValidationByType = function(type, numberOfReference){
  numberOfReference = parseInt(numberOfReference)
  if (numberOfReference != NaN && numberOfReference > 0){
    for(var i = 0; i < numberOfReference; i++){
      $('#' + type + '_reference_first_name_' + i).rules('add', {
        required: true,
        alphabetsnspace: true,
        minlength: 2,
        maxlength: 40,
        messages: {
          alphabetsnspace: 'Please use alphabets only.',
          required: 'Please enter first name.',
          minlength: 'Sorry, your first name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your first name must be between 2 and 40 characters long.'
        }
      });

      $('#' + type + '_reference_last_name_' + i).rules('add', {
        required: true,
        alphabetswithspace: true,
        minlength: 2,
        maxlength: 40,
        messages: {
          alphabetswithspace: 'Please use alphabets only.',
          required: 'Please enter last name.',
          minlength: 'Sorry, your last name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your last name must be between 2 and 40 characters long.'
        }
      });

      $('#' + type + '_reference_phone_number_' + i).rules('add', {
        required: true,
        minlength: 12,
        maxlength: 12,
        phoneregex: true,
        messages: {
          required: 'Please enter mobile phone.',
          minlength: 'Your phone number must be 10 numeric digits only.',
          maxlength: 'Your phone number must be 10 numeric digits only.',
          phoneregex: 'Please enter valid mobile phone.',
        }
      });
    }
  }

  $.validator.addMethod("phoneregex", function(value, element) {
    return this.optional(element) || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value);
  });
  $.validator.addMethod("alphabetsnspace", function(value, element) {
    return this.optional(element) || /^[a-zA-Z]+$/.test(value);
  });
  $.validator.addMethod("alphabetswithspace", function(value, element) {
    return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
  });
}
