document.addEventListener("turbolinks:load", () => {
  $("#new_admin_user").validate({
    rules: {
      "admin_user[email]": {
        required: true
      },
      "admin_user[password]": {
        required: true
      }
    },
    messages: {
      "admin_user[password]": "The password is required and can\'t be empty",
      "admin_user[email]": {
        required: "The email address is required and can\'t be empty"
      }
    },
  });

  $("#new_member").validate({
      rules: {
        "#member_login": {
          required: true
        },
        "member[password]": {
          required: true
        }
      },
      messages: {
        "member[password]": "The password is required and can\'t be empty",
        "member[login]": {
          required: "The email address is required and can\'t be empty"
        }
      },
    });

  if($("#basic_details").length == 1){
    var min_loan_amount = parseInt($("#min_loan_amount").val())
    var max_loan_amount = parseInt($("#max_loan_amount").val())
    $("#basic_details").validate({
      rules: {
        "applicant[first_name]": {
          required: true,
          lastNameValidation: true,
          minlength: 2,
          maxlength: 40
        },
        "applicant[last_name]": {
          required: true,
          lastNameValidation: true,
          minlength: 2,
          maxlength: 40
        },
        "applicant[loan_amount]": {
          required: true,
          digits: true,
          min: min_loan_amount,
          max: max_loan_amount
        },
        "applicant[address]": {
          required: true,
          addressregx: true,
          minlength: 2,
          maxlength: 100
        },
        "applicant[city]": {
          required: true,
          minlength: 2,
          maxlength: 40,
          cityregex: true
        },
        "applicant[apt_unit]": {
          maxlength: 20,
          aptunit: true,
          required: false
        },
        "applicant[state_id]": {
          required: true
        },
        "applicant[loan_purpose]": {
          required: true
        },
        "applicant[zip]": {
          required: true,
          zipregex: true
        },
        "applicant[phone]": {
          required: true,
          phoneregex: true,
          minlength: 12,
          maxlength: 12
        }
      },
      messages: {
        "applicant[first_name]": {
          lastNameValidation: "The first name can only consist of alphabets.",
          required: "The first name is required.",
          minlength: "The first name must be more than 2 and less than 40 characters long.",
          maxlength: "The first name must be more than 2 and less than 40 characters long."
        },
        "applicant[last_name]": {
          lastNameValidation: "The last name can only consist of alphabets.",
          required: "The last name is required.",
          minlength: "The last name must be more than 2 and less than 40 characters long.",
          maxlength: "The last name must be more than 2 and less than 40 characters long."
        },
        "applicant[loan_amount]": {
          required: "Please enter loan amount in USD.",
          digits: "The value is not an integer",
          min: "The loan amount must be from $"+min_loan_amount+" and $"+max_loan_amount,
          max: "The loan amount must be from $"+min_loan_amount+" and $"+max_loan_amount
        },
        "applicant[address]": {
          required: 'The Address is required.',
          minlength: 'The address must be more than 2 and less than 100 characters long.',
          maxlength: 'The address must be more than 2 and less than 100 characters long.',
          addressregx: 'The address must be alphanumeric.'
        },
        "applicant[city]": {
          required: 'The City is required.',
          minlength: 'The city must be more than 2 and less than 40 characters long.',
          maxlength: 'The city must be more than 2 and less than 40 characters long.',
          cityregex: 'The city name is not valid.'
        },
        "applicant[apt_unit]": {
          maxlength: 'The apt/unit must be less than 20 characters long.',
          aptunit: 'The apt/unit must be alphanumeric.'
        },
        "applicant[state_id]": {
          required: 'Please select State.'
        },
        "applicant[loan_purpose]": {
          required: 'Please select Loan Purpose.'
        },
        "applicant[zip]": {
          required: 'The zip code is required.',
          zipregex: 'The zip code is not valid.'
        },
        "applicant[phone]": {
          required: "Mobile Phone is required.",
          phoneregex: "The Mobile Phone is not valid.",
          minlength: "The Mobile Phone must be 10 characters long.",
          maxlength: "The Mobile Phone must be 10 characters long."
        }
      }
    });
  }
  if($("#member_id").length == 1){
    $("#member_id").validate({
      rules: {
        "member[login_id]": {
          required: true
        },
        "member[name]": {
          required: true
        },
        "member[email]": {
          required: true,
          emailregex: true
        },
        "member[password]": {
          required: true,
          passwordregex: true
        },
        "member[password_confirmation]": {
          required: true,
          passwordregex: true
        }
      },
      messages: {
        "member[login_id]": "The loginid is required and can\'t be empty",
        "member[name]": {
          required: "Full name is required and can\'t be empty"
        },
        "member[email]": {
          required: "The email address is required and can\'t be empty",
          emailregex: 'The input is not a valid email address'
        },
        "member[password]": {
          required: "The password is required and can\'t be empty",
          passwordregex: 'Password must be contain Minimum 8 characters and includes upper and lower case letters and at least one number'
        },
        "member[password_confirmation]": {
          required: "The confirm password is required and can\'t be empty",
          passwordregex: 'Password must be contain Minimum 8 characters and includes upper and lower case letters and at least one number'
        }
      },
    });
  }

  $('#apply-forms').on('mouseover', function() {

    if($("#co_basic_details").length == 1){
      var min_loan_amount = parseInt($("#min_loan_amount").val())
      var max_loan_amount = parseInt($("#max_loan_amount").val())
      $("#co_basic_details").validate({
        rules: {
         "applicant[co_first_name]": {
            required: true,
            lastNameValidation: true,
            minlength: 2,
            maxlength: 40
          },
          "applicant[co_last_name]": {
            required: true,
            lastNameValidation: true,
            minlength: 2,
            maxlength: 40
          },
          "applicant[co_address]": {
            required: true,
            addressregx: true,
            minlength: 2,
            maxlength: 100
          },
          "applicant[co_city]": {
            required: true,
            minlength: 2,
            maxlength: 40,
            cityregex: true
          },
          "applicant[co_state_id]":{
            required: true
          },
          "applicant[co_apt_unit]": {
            maxlength: 20,
            aptunit: true
          },
          "applicant[co_zip]": {
            required: true,
            zipregex: true
          },
          "applicant[co_phone]":{
            required: true,
            phoneregex: true,
            maxlength: 12,
            minlength:12
          }
        },
        messages: {
          "applicant[co_first_name]": {
            lastNameValidation: "The first name can only consist of alphabets.",
            required: "The first name is required.",
            minlength: "The first name must be more than 2 and less than 40 characters long.",
            maxlength: "The first name must be more than 2 and less than 40 characters long."
          },
          "applicant[co_last_name]": {
            lastNameValidation: "The last name can only consist of alphabets.",
            required: "The last name is required.",
            minlength: "The last name must be more than 2 and less than 40 characters long.",
            maxlength: "The last name must be more than 2 and less than 40 characters long."
          },
          "applicant[co_address]": {
            required: 'The address is required.',
            minlength: 'The address must be more than 2 and less than 100 characters long.',
            maxlength: 'The address must be more than 2 and less than 100 characters long.',
            addressregx: 'The address must be alphanumeric.'
          },
          "applicant[co_city]": {
            required: 'The City is required.',
            minlength: 'The city must be more than 2 and less than 40 characters long.',
            maxlength: 'The city must be more than 2 and less than 40 characters long.',
            cityregex: 'The city name is not valid.'
          },
          "applicant[co_state_id]": {
            required: 'Please select State.'
          },
          "applicant[co_apt_unit]": {
            maxlength: 'The apt/unit must be less than 20 characters long.',
            aptunit: 'The apt/unit must be alphanumeric.'
          },
          "applicant[co_zip]": {
            required: 'The zip code is required.',
            zipregex: 'The zip code is not valid.'
          },
          "applicant[co_phone]": {
            required: "Mobile Phone is required.",
            phoneregex: "The Mobile Phone is not valid.",
            minlength: "The Mobile Phone must be 10 characters long.",
            maxlength: "The Mobile Phone must be 10 characters long."
          }
        }
      });
    }

    if($("#personal_details").length == 1){
      $("#personal_details").validate({
        rules: {
          "applicant[email]": {
            required: true,
            emailregex: true,
            minlength: 2,
            maxlength: 100
          },
          "applicant[birth_date]": {
            required: true
          },
          "applicant[gross_annual_income]": {
            required: true,
            amountregx: true,
            maxlength: 10
          },
          "applicant[home_type]": {
            required: true
          },
          "applicant[monthly_housing_payment]": {
            required: true,
            amountregx: true,
            maxlength: 7
          },
          "applicant[active_military]": {
            required: true
          },
          "applicant[ssn]": {
            required: true,
            ssnregex: true,
            minlength: 11,
            maxlength: 11
          }
        },
        messages: {
         "applicant[email]": {
            required: "The email address is required.",
            emailregex: "The email address is not valid.",
            maxlength: "The email must be more than 2 and less than 100 characters long.",
            minlength: "The email must be more than 2 and less than 100 characters long."
          },
          "applicant[birth_date]": {
            required: 'The date of birth is required'
          },
          "applicant[gross_annual_income]": {
            required: "Please enter gross income amount in USD.",
            amountregx: "The value is not an integer",
            maxlength: "Amount must be less than $10,000,000."
          },
          "applicant[home_type]": {
            required: 'Please select Rent/Own.'
          },
          "applicant[monthly_housing_payment]": {
            required: 'Please enter monthly housing payment in USD.',
            amountregx: 'The value is not an integer',
            maxlength: "Amount must be less than $100,000."
          },
          "applicant[active_military]": {
            required: 'Please select Yes/No.'
          },
          "applicant[ssn]": {
            required: 'Please provide the Social Security number',
            ssnregex: 'The SSN must be 9 characters long.',
            maxlength: "The SSN must be 9 characters long.",
            minlength: "The SSN must be 9 characters long."
          }
        }
      });
    }

    if($("#co_personal_details").length == 1){
      $("#co_personal_details").validate({
        rules: {
          "applicant[co_email]": {
            required: true,
            emailregex: true,
            minlength: 2,
            maxlength: 100
          },
          "applicant[co_birth_date]": {
            required: true
          },
          "applicant[co_gross_annual_income]": {
            required: true,
            amountregx: true,
            maxlength: 7
          },
          "applicant[co_home_type]": {
            required: true
          },
          "applicant[co_monthly_housing_payment]": {
            required: true,
            amountregx: true,
            maxlength: 7
          },
          "applicant[co_ssn]": {
            required: true,
            ssnregex: true
          }
        },
        messages: {
         "applicant[co_email]": {
            required: "The email address is required.",
            emailregex: "The email address is not valid.",
            maxlength: "The email must be more than 2 and less than 100 characters long.",
            minlength: "The email must be more than 2 and less than 100 characters long."
          },
          "applicant[co_birth_date]": {
            required: 'The date of birth is required'
          },
          "applicant[co_gross_annual_income]": {
            required: "Please enter gross income amount in USD.",
            amountregx: "The value is not an integer",
            maxlength: "Amount must be less than $100,000."
          },
          "applicant[co_home_type]": {
            required: 'Please select Rent/Own.'
          },
          "applicant[co_monthly_housing_payment]": {
            required: 'Please enter monthly housing payment in USD.',
            amountregx: 'The value is not an integer',
            maxlength: "Amount must be less than $100,000."
          },
          "applicant[co_ssn]": {
            required: 'Please provide the Social Security number',
            ssnregex: 'The SSN must be 9 characters long.',
            maxlength: "The SSN must be 9 characters long.",
            minlength: "The SSN must be 9 characters long."
          }
        }
      });
    }

    if($("#sensitive_details").length == 1){
      $("#sensitive_details").validate({
        rules: {
          "applicant[payment_frequency]": {
            required: true
          },
          "applicant[next_payment_date]": {
            required: true
          },
          "applicant[account_card_number]": {
            required: true,
            digits: true,
            accountcardregex: true,
            minlength: 16,
            maxlength: 16
          },
          "applicant[card_exp_month]": {
            required: true,
            minlength: 1,
            maxlength: 2,
            min: 1,
            max: 12
          },
          "applicant[card_exp_year]": {
            required: true,
            digits: true
          },
          "applicant[card_cvv]": {
            required: true,
            digits: true,
            minlength: 3,
            maxlength: 4
          },
          "applicant[bank_aba]": {
            required: true,
            digits: true,
            minlength: 9,
            maxlength: 9
          },
          "applicant[bank_account_number]": {
            required: true,
            digits: true,
            minlength: 5,
            maxlength: 25
          }
        },
        messages: {
          "applicant[payment_frequency]": {
            required: "Please Select your pay frequency."
          },
          "applicant[next_payment_date]": {
            required: "Next Payment Date is required."
          },
          "applicant[account_card_number]": {
            required: "Card number is required",
            accountcardregex: "The format of card number is invalid.",
            digits: "The format of card number is invalid.",
            minlength: 'Card number must be 16 digit.',
            maxlength: 'Card number must be 16 digit.'
          },
          "applicant[card_exp_month]": {
            required: 'Card expiry month is required.',
            minlength: '',
            maxlength: '',
            min: 'The month must be between 1 and 12',
            max: 'The month must be between 1 and 12'
          },
          "applicant[card_exp_year]": {
            required: 'Card expiry year is required',
            digits: 'The value is not an integer'
          },
          "applicant[card_cvv]": {
            required: 'Please enter vaild cvv.',
            digits: 'The value is not an integer',
            maxlength: "Please enter value between 3 and 4 characters long.",
            minlength: "Please enter value between 3 and 4 characters long."
          },
          "applicant[bank_aba]": {
            required: 'Bank ABA is required.',
            digits: 'The value is not an integer.',
            maxlength: "The ABA must be 9 characters long.",
            minlength: "The ABA must be 9 characters long."
          },
          "applicant[bank_account_number]": {
            required: 'Bank account number required.',
            digits: 'The value is not an integer.',
            maxlength: "Please enter value between 5 and 25 characters long.",
            minlength: "Please enter value between 5 and 25 characters long."
          },
        }
      });
    }

    $.validator.addMethod("passwordregex", function(value, element) {
      return this.optional(element) || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/i.test(value);
    });
    $.validator.addMethod("accountcardregex", function(value, element) {
      return this.optional(element) || /^[0-9]*$/.test(value);
    });
    $.validator.addMethod("ssnregex", function(value, element) {
      return this.optional(element) || /^(\+\d{1,1}\s)?\(?\d{3}\)?[\s.-]\d{2}[\s.-]\d{4}$/.test(value);
    });
    $.validator.addMethod("phoneregex", function(value, element) {
      return this.optional(element) || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value);
    });
    $.validator.addMethod("alphabetsnspace", function(value, element) {
      return this.optional(element) || /^[a-zA-Z]+$/.test(value);
    });
    $.validator.addMethod("lastNameValidation", function(value, element) {
      return this.optional(element) || /^([a-zA-Z., '-])*[a-zA-Z., '-]+$/.test(value);
    });

    $.validator.addMethod("alphabetswithspace", function(value, element) {
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    });
    $.validator.addMethod("alphanumeric", function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9 ]+$/.test(value);
    });
    $.validator.addMethod("aptunit", function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9 ,!@#\$%\^\&*\)\(+=._'-]+$/.test(value);
    });
    $.validator.addMethod("addressregx", function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9 .,-]+$/.test(value);
    });
    $.validator.addMethod("amountregx", function(value, element) {
      return this.optional(element) || /^[0-9,]+$/.test(value);
    });
    $.validator.addMethod("emailregex", function(value, element) {
      return this.optional(element) || /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(value);
    });
    $.validator.addMethod("cityregex", function(value, element) {
      return this.optional(element) || /^[a-zA-Z ]+(?:[- ][a-zA-Z ]+)*$/.test(value);
    });
    $.validator.addMethod("zipregex", function(value, element) {
      return this.optional(element) || /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    });

    $('#applicant_next_payment_date').datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      todayHighlight: true,
      startDate: '+1D',
      endDate: '+7D'
    });

    $('#applicant_next_payment_date').change(function(){
      $(this).datepicker('hide');
    })

    $(".birthDate").datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      startDate: '01/01/1900',
      endDate: '-18Y'
    });

    $("#applicant_expiration_date").datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      startDate: new Date()  
    });

    $("#applicant_co_expiration_date").datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      startDate: new Date()  
    });

    $('form input[type=checkbox]').change(function() {
     if($('#applicant_checking').is(":unchecked") && $('#applicant_savings').is(":unchecked")){
        $('#applicant_auto_payment_method').find('option').not(':first').remove();
        $("#applicant_auto_payment_method").append($('<option>', {value:'debit_card', text:'Debit Card'}));
        $("#applicant_auto_payment_method").append($('<option>', {value:'credit_card', text:'Credit Card'}));
     } 
      if($('#applicant_checking').is(":checked")){
        $("#applicant_auto_payment_method").append($('<option>', {value:'checking', text:'Checking'}));
      }
      else {
        $('#applicant_auto_payment_method').find('option').not(':first').remove();
        $("#applicant_auto_payment_method").append($('<option>', {value:'debit_card', text:'Debit Card'}));
        $("#applicant_auto_payment_method").append($('<option>', {value:'credit_card', text:'Credit Card'}));
      }
     if($('#applicant_savings').is(":checked")){
        $("#applicant_auto_payment_method").append($('<option>', {value:'savings', text:'Savings'}));
     }
     else
      { 
        $('#applicant_auto_payment_method').find('option').not(':first').remove();
        $("#applicant_auto_payment_method").append($('<option>', {value:'debit_card', text:'Debit Card'}));
        $("#applicant_auto_payment_method").append($('<option>', {value:'credit_card', text:'Credit Card'}));
      }
    });

    
    $(".saleComplete ").datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true
    });

    $('#applicant_loan_amount').focus(function(){
      $('#loan_amount_formatter').val($('#applicant_loan_amount').val())
      $('#loan_amount_formatter').focus()
    });

    $('#applicant_monthly_housing_payment, #applicant_co_monthly_housing_payment, #applicant_gross_annual_income, #applicant_co_gross_annual_income').keyup(function(){
      numberFormatter($(this))
    })

    $('#applicant_monthly_housing_payment, #applicant_co_monthly_housing_payment, #applicant_gross_annual_income, #applicant_co_gross_annual_income').blur(function(){
      numberFormatter($(this))
    })

    $('#loan_amount_formatter').keyup(function(){
      numberFormatterWithDecimal($(this))
      $('#applicant_loan_amount').val(this.value.replace(/,/g , ''))
    })

    $('#loan_amount_formatter').blur(function(){
      numberFormatterWithDecimal($(this))
      $('#applicant_loan_amount').val(this.value.replace(/,/g , ''))
    })

    $('#applicant_phone').keyup(function(){
      addDashes($(this))
    })

     $('#applicant_co_phone').keyup(function(){
      addDashes($(this))
    })

    $('#applicant_employer').keyup(function(){
      addDashes($(this))
    })

    $('#applicant_employer_phone').keyup(function(){
      addDashes($(this))
    })
   

    $('#applicant_co_employer_phone').keyup(function(){
      addDashes($(this))
    })

    $('#applicant_ssn').keyup(function(){
      addDashes($(this))
    })

    $('#applicant_birth_date, #applicant_next_payment_date, #applicant_co_birth_date').keyup(function(){
      addSlash($(this))
    })

    $('#applicant_co_ssn').keyup(function(){
      addDashes($(this))
    })
     
    $('#applicant_first_name, #applicant_last_name, #applicant_co_first_name, #applicant_co_last_name, #applicant_city, #applicant_co_city, #applicant_address, #applicant_co_address, #applicant_co_apt_unit, #applicant_apt_unit').on('blur', function() {
      var inputText = $(this).val();
      // Replace multiple spaces with a single space
      var cleanedText = inputText.replace(/\s+/g, ' ');
      // Update the input field value
      $(this).val(cleanedText);
       
      var trimmedText = $(this).val().trim();
      $(this).val(trimmedText);
    });

    $('#applicant_co_apt_unit, #applicant_apt_unit').on('blur', function() {
      var inputText = $(this).val();
      // Replace multiple spaces with a single space
      var cleanedText = inputText.replace(/\s+/g, '');
      // Update the input field value
      $(this).val(cleanedText);
      var trimmedText = $(this).val().trim();
      $(this).val(trimmedText);
    });

    $('#basic_details, #personal_details, #sensitive_details, #co_basic_details, #co_personal_details, #emi_calculator_id, #product_detail_form').on('keyup, change', function(){
      validation_checker()
    });

    $('#basic_details input, #personal_details input, #sensitive_details input, #co_basic_details input, #co_personal_details input, #emi_calculator_id input, #product_detail_form input').on('keyup', function(){
      validation_checker()
    });

    $('.btn-continue').on('mouseover', function(){
      validation_checker()
    });

    $('.btn-continue').on('click', function(){
      timeout_session($('.encoded_url').data('encodedUrl'))
    });

    $('#applicant_card_exp_year, #applicant_card_exp_month').change(function(){
      card_exp_year = $('#applicant_card_exp_year').val();
      if(parseInt(card_exp_year) == new Date().getFullYear() ){
        month = new Date().getMonth() + 1
        exp_month = $('#applicant_card_exp_month').val();
        $('#applicant_card_exp_month').rules('add', {
          min: month,
          messages: {
            min: 'Sorry, Card expiry month must be between ' + month + ' and 12'
          }
        });
      }else{
        $('#applicant_card_exp_month').rules('add', {
          min: 1,
          messages: {
            min: 'Sorry, Card expiry month must be between 1 and 12'
          }
        });
      }
    });
  });

  $('#product-forms').on('mouseover', function(){
    validation_checker()
    productRulesSet()
  })

  //this js is used for igw_forms
  $('#product_detail_form').on('change', function(){
    validation_checker()
  });

  $('#routing_number, #bank_name, #account_number').on('keyup', function(){
    validation_checker()
  })

  if($('#product_details_form').length == 1){
    $('#product_details_form').validate({
       rules: {
        'applicant[product_discription]': {
          required: true,
          minlength: 2,
          maxlength: 100
        },
        'applicant[product_amount]': {
          required: true,
          min: 1
        },
        'applicant[sale_tax]': {
          required: true,
          min: 0
        },
        'applicant[sale_total_amount]': {
          required: true,
        },
        'applicant[deposit]': {
          required: true,
          min: 0
        }
      },
      messages: {
        'applicant[product_discription]': {
          required: 'Please enter product description.',
          minlength: 'The product description must be between 2 and 100 characters long.',
          maxlength: 'The product description must be between 2 and 100 characters long.'
        },
        'applicant[product_amount]': {
          required: 'Please enter purchase price.',
          min: 'Purchase price should be greater than or equal to 1.',
        },
        'applicant[sale_tax]': {
          required: 'Please enter sales tax.',
          min: 'Please enter sales tax.',
        },
        'applicant[sale_total_amount]': {
          required: '',
        },
        'applicant[deposit]': {
          required: 'Please enter down payment.',
          min: 'Sorry, down payment can not be greater than purchase price.'
        }
      },
    });
  }

  if ($('#bank_info_form').length == 1) {
    $('#bank_info_form').validate({
        rules: {
        'routing_number': {
          minlength: 9,
          maxlength: 9,
          required: true,
          digits: true,
          allZero: true
        },
        'account_number': {
          required: true,
          digits: true,
          minlength: 6,
          maxlength: 25,
          allZero: true
        },
        'bank_name':{
          maxlength: 256,
          required: true
        }
      },
      messages: {
        'routing_number': {
          minlength: 'Sorry, routing number must be 9 digits',
          maxlength: 'Sorry, routing number must be 9 digits',
          digits: 'Sorry, only numbers are allowed.',
          allZero: 'Sorry, number is not valid.'

        },
        'account_number': {
          maxlength: "Sorry, account number must be between 6 and 25 digits.",
          minlength: "Sorry, account number must be between 6 and 25 digits.",
          digits: 'Sorry, only numbers are allowed.',
          allZero: 'Sorry, number is not valid.'
        },
        'bank_name':{
          maxlength: "Sorry, bank name must be less then 256 words"
        }
      }
    });
  }

  // $(".product_amount").change(function(){
  //   product_amount = $(".product_amount").val();
  //   service_fee = 0
  //   if($("#applicant_service_fee").length > 0){
  //     service_fee = $("#applicant_service_fee").val();
  //   }
  //   sale_tax = $("#applicant_sale_tax").val();
  //   total = parseInt(product_amount) + parseInt(service_fee) + parseInt(sale_tax);
  //   $("#applicant_sale_total_amount").val(total);
  // });

  // $("#applicant_sale_tax").change(function(){
  //   sale_tax = $("#applicant_sale_tax").val();
  //   service_fee = 0
  //   if($("#applicant_service_fee").length > 0){
  //     service_fee = $("#applicant_service_fee").val();
  //   }
  //   product_amount = $(".product_amount").val();
  //   total =  parseInt(product_amount)+ parseInt(sale_tax) + parseInt(service_fee)
  //   $("#applicant_sale_total_amount").val(total);
  // });

  // $("#product_details_form").mouseover(function(){
  //   let totalAmount = $('#applicant_sale_total_amount').val();
  //   if(totalAmount > 0){
  //     let maxMinAmount = downPaymentMinMax(totalAmount)
  //     $('#applicant_deposit').attr('max', maxMinAmount.max)
  //     $('#applicant_deposit').attr('min', maxMinAmount.min)
  //   }
  // });
  // $("#product_details_form input").keyup(function(){
  //   let totalAmount = $('#applicant_sale_total_amount').val();
  //   if(totalAmount > 0){
  //     let maxMinAmount = downPaymentMinMax(totalAmount)
  //     $('#applicant_deposit').attr('max', maxMinAmount.max)
  //     $('#applicant_deposit').attr('min', maxMinAmount.min)
  //   }
  // });
});

window.totalAmountIgw = function(){
  let service_fee = 0
  let sale_tax    = 0
  let amount      = 0
  let total       = 0
  let shipping_fee = 0

  console.log('form validation call total')

  if($("#applicant_service_fee").val().length > 0)
    service_fee = $("#applicant_service_fee").val();
  if($("#applicant_shipping_fee").val().length > 0)
    shipping_fee = $("#applicant_shipping_fee").val();
  if($("#applicant_sale_tax").val().length > 0)
    sale_tax    = $("#applicant_sale_tax").val();
  if($(".product_amount").val().length > 0)
    amount      = $(".product_amount").val();

  new_total       =  parseFloat(amount) + parseFloat(sale_tax)
  total       =  parseFloat(amount) + parseFloat(sale_tax) + parseInt(service_fee) + parseFloat(shipping_fee)

  // product_amount_max = 50000 - (parseFloat(sale_tax) + parseInt(service_fee))

  // if(product_amount_max < 1){
  //   product_amount_max = 1
  // }

  // $('#applicant_product_amount').rules('add', {
  //   max: product_amount_max,
  //   messages: {
  //     max: 'Purchase price cannot be greater than ' + product_amount_max.toFixed(2) + '.'
  //   }
  // });

  // $('#applicant_product_amount').attr('max', product_amount_max.toFixed(2))
  $("#applicant_sale_total_amount").val(total.toFixed(2));
  $("#applicant_sale_total_amount_new").val(new_total.toFixed(2));
}

window.loanAmountCal = function(){
  let deposit_amount = 0
  let total_amount   = 0
  let service_fee = 59
  let shipping_fee = 0
  if($("#applicant_deposit").val().length > 0)
    deposit_amount = $("#applicant_deposit").val();
  if($("#applicant_sale_total_amount").val().length > 0)
    total_amount = $("#applicant_sale_total_amount").val();
  if($("#applicant_service_fee").val().length > 0)
    service_fee = $("#applicant_service_fee").val();
  if($("#applicant_shipping_fee").val().length > 0)
    shipping_fee = $("#applicant_shipping_fee").val();

  let loanAmount = parseFloat(total_amount) - parseFloat(deposit_amount) - parseFloat(service_fee) - parseFloat(shipping_fee)
  if(loanAmount < 0){
    loanAmount = 0
  }

  let term;
  let igw_term = parseInt($('#custom_term').val())

  if(String(igw_term) != 'NaN' && igw_term > 0){
    term = igw_term
  }else{
    term = 0
  }

  let apr        = parseFloat($('#igw_apr').val())
  let paymentAmount = emiCalculator(apr, loanAmount, term)

  let initial_payment =  parseFloat(deposit_amount) + parseFloat(service_fee) + parseFloat(shipping_fee)

  if(String(paymentAmount) == 'NaN' || String(paymentAmount) == 'Infinity'){
    paymentAmount = 0.0
  }
  $('.termSection').text(term);
  $('.paymentAmountSection').text(paymentAmount.toFixed(2));
  $('.loanAmountSection').text(loanAmount.toFixed(2));
  $('.InitialPaymentAmountSection').text(initial_payment.toFixed(2))
}

window.emiCalculator = function(apr, loan_amount, terms){
  if(apr > 0){
    apr = (apr/1200)
    x = (1 + apr) ** terms
    return (loan_amount * (apr * x) / (x - 1));
  }else{
    return (loan_amount / terms);
  }
}

window.downPaymentMinMax = function(){
  let amount = $('#applicant_sale_total_amount').val();
  let min = 0
  let max = 7000
  if(amount > 0){
    // min = parseFloat(amount)*0.1
    // max = parseFloat(amount)*0.25
    max = parseFloat(amount)
  }
  $('#applicant_deposit').attr('max', max.toFixed(2))
  $('#applicant_deposit').attr('min', min.toFixed(2))
  return { "max": max.toFixed(2), "min": min.toFixed(2) }
}

window.productRulesSet = function(){
  $('#product_details').validate()
  if($('.nested-fields').length >= 10){
    $('.add-item').hide();
  }
  else{
    $('.add-item').show();
  }
}

window.add_item_checker = function(){
  let inputsWithValues = 0, totalInputs = 0;
  let myInputs         = $('.nested-fields input:required');
  myInputs.each(function(e) {
    if ($(this).val()) {
      if($(this).valid()){
        inputsWithValues += 1;
        //$(this).prev().prev().css('color', '#3c7643')
      }
      else{
        //$(this).prev().prev().css('color', '#a94442')
      }
    }
  });

  if (inputsWithValues == totalInputs) {
    $(".add-item").attr("disabled", false);
  } else {
    $(".add-item").attr("disabled", true);
  }
}

// window.document_form_validations = function(event){
//   var message = ''
//   if (event.name == 'reference[][first_name]' || event.name == 'reference[][last_name]') {
//     if (!(/^[a-zA-Z]+$/.test(event.value))) {
//       message = 'Please use alphabets only.'
//     }else if (event.value.length < 2 || event.value.length > 40 ) {
//       message = 'Sorry, your first name must be between 2 and 40 characters long.'
//     }else {
//       message = ''
//     }
//   }else if (event.name == 'reference[][phone_number]') {
//     if (event.value.length < 12 || event.value.length > 12) {
//       message = 'Your phone number must be 10 numeric digits only.'
//     } else if(!(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value))){
//       message = 'Please enter valid mobile phone.'
//     }else {
//       message = ''
//     }
//   }
//   $('#' + event.id + '_label').html(message)
// }

window.validation_checker = function(){
  let inputsWithValues = 0, totalInputs = 0;
  let myInputs         = $('input:required, select:required, textarea:required');
  myInputs.each(function(e) {
    if ($(this).val()) {
      if($(this).valid()){
        inputsWithValues += 1;
        //$(this).prev().prev().css('color', '#3c7643')
      }
      else{
        //$(this).prev().prev().css('color', '#a94442')
      }
    }
  });

  totalInputs = myInputs.length
  if($('#consent-check-box').length == 1){
    totalInputs += 1
    inputsWithValues += $('#consent-check-box:checked').length == 1 ? 1 : 0
  }

  if ( $('#product_details').length == 1 && $('.nested-fields').length == 0) {
    totalInputs += 1
  }

  // if($('#product_detail_form').length   == 1){
  //   totalInputs +=1
  // }

  if (inputsWithValues == totalInputs) {
    $(".btn-continue").prop("disabled", false);
  } else {
    $(".btn-continue").prop("disabled", true);
  }
}


window.nextPaymentDateLimit = function(){
  let paymentFrequency = $('#applicant_payment_frequency').val()
  $('#applicant_next_payment_date').val('')
  switch(paymentFrequency) {
   case 'weekly': {
      // must be within the next 7 days from today
      $('#applicant_next_payment_date').datepicker('setEndDate', '+7D');
      break;
    }
    case 'every_2_weeks': {
      // must be within the next 14 days from today
      $('#applicant_next_payment_date').datepicker('setEndDate', '+14D');
      break;
    }
    case 'twice_per_month': {
      // must be within the next 28 days from today
      $('#applicant_next_payment_date').datepicker('setEndDate', '+28D');
      break;
    }
    case 'monthly': {
      // must be within the next 31 days from today
      $('#applicant_next_payment_date').datepicker('setEndDate', '+31D');
      break;
    }
    default: {
      break;
    }
  }
}


window.nextPaymentDateValid = function(){
  let paymentFrequency = $('#applicant_payment_frequency').val()
  let nextPaymentDate  = $('#applicant_next_payment_date').val()
  let PaymentDate;
  if(nextPaymentDate.length < 8){
    return false;
  }

  nextPaymentDate = nextPaymentDate.split('/')
  let paymentDate = new Date(nextPaymentDate[2], nextPaymentDate[0] - 1, nextPaymentDate[1] )

  let date = new Date();
  switch(paymentFrequency) {
   case 'weekly': {
      // must be within the next 7 days from today
      date.setDate(date.getDate() + 7);
      $('#applicant_next_payment_date').datepicker('setEndDate', '+7D');
      break;
    }
    case 'every_2_weeks': {
      // must be within the next 14 days from today
      date.setDate(date.getDate() + 14);
      $('#applicant_next_payment_date').datepicker('setEndDate', '+14D');
      break;
    }
    case 'twice_per_month': {
      // must be within the next 28 days from today
      date.setDate(date.getDate() + 28);
      $('#applicant_next_payment_date').datepicker('setEndDate', '+28D');
      break;
    }
    case 'monthly': {
      // must be within the next 31 days from today
      date.setDate(date.getDate() + 31);
      $('#applicant_next_payment_date').datepicker('setEndDate', '+31D');
      break;
    }
    default: {
      break;
    }

  }

  let today = new Date();
  today.setDate(today.getDate())

  today.setHours(23,59,59,59)
  date.setHours(23,59,59,59)
  paymentDate.setHours(0,0,0,0)

  if(date <= paymentDate || today >= paymentDate){
    $('#applicant_next_payment_date').val('')
  }

}


